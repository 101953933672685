import React from "react"
import * as theme from "../../../theme"
import { SmallSectionHeader } from "../../../components/page"
import headerImage from "../section-header.jpg"
import Layout from "../../../layout"
import { ProgrammesOfStudyBox } from "../../../components/boxes"

export default () => {
  return (
    <Layout title="How did they build that?">
      <SmallSectionHeader
        color={theme.colors.yellow.dark}
        image={headerImage}
        to="/how-did-they-build-that"
      >
        How did they build that?
      </SmallSectionHeader>
      <ProgrammesOfStudyBox
        items={[
          {
            label: "Key Stage 1",
            items: [
              {
                title: "Everyday Materials",
                content:
                  "Identify and name a variety of everyday materials, including wood, plastic, glass, metal, water and rock and describe the simple physical properties of a variety of everyday materials. Distinguish between an object and the material from which it is made. Compare and group together a variety of everyday materials on the basis of their simple physical properties.)",
              },
            ],
          },
          {
            label: "Lower Key Stage 2",
            items: [
              {
                title: "Everyday Materials",
                content:
                  "Identify and compare the suitability of a variety of everyday materials, including wood, metal, plastic, glass, brick, rock, paper and cardboard for particular uses. Find out how the shapes of solid objects made from some materials can be changed by squashing, bending, twisting and stretching (for example, the lead in the Stained Glass windows).",
              },
            ],
          },
          {
            label: "Upper Key Stage 2",
            items: [
              {
                title: "Properties & Changes of Materials",
                content:
                  "Explain that some changes result in the formation of new materials, and that this kind of change is not usually reversible (for example making glass), including changes associated with burning and the action of acid on bicarbonate of soda ( e.g. making glass)",
              },
              {
                title: "Forces",
                content:
                  "Explain that unsupported objects fall towards the Earth because of the force of gravity acting between the Earth and the falling object (for example, the force of gravity acts on the weight of an arch to hold it in position). Recognise that some mechanisms, including levers, pulleys and gears, allow a smaller force to have a greater effect.",
              },
            ],
          },
        ]}
      />
    </Layout>
  )
}
